import "leaflet.awesome-markers";
import "leaflet.awesome-markers/dist/leaflet.awesome-markers";
import { MapOptions } from "leaflet";

const mapConfig: MapOptions = {
  minZoom: 4,
  maxZoom: 15,
};

const mapIcons = {
  srcIcon: window.L.AwesomeMarkers.icon({
    icon: "chevron-right",
    prefix: "fa",
    markerColor: "blue",
  }),

  dstIcon: window.L.AwesomeMarkers.icon({
    icon: "flag-checkered",
    prefix: "fa",
    markerColor: "blue",
  }),

  lastLiveIcon: window.L.AwesomeMarkers.icon({
    icon: "car",
    prefix: "fa",
    markerColor: "green",
  }),

  hoverIcon: window.L.AwesomeMarkers.icon({
    icon: "car",
    prefix: "fa",
    markerColor: "red",
  }),

  maxIcon: window.L.AwesomeMarkers.icon({
    icon: "caret-down",
    iconColor: "white",
    prefix: "fa",
    markerColor: "red",
  }),
};

const tileProviders = [
  {
    name: "Dark Map",
    visible: true,
    url: `https://maps.hereapi.com/v3/base/mc/{z}/{x}/{y}/png8?style=explore.night&apiKey=${process.env.VUE_APP_HERE_API_KEY}`,
    attribution: '&copy; <a href="https://here.com">HERE</a>',
  },
];

export { mapConfig, mapIcons, tileProviders };
